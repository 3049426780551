<!-- src/components/FootModuleLoader.vue -->
<template>
  <section id="foot_module">
    <component :is="footModuleComponent" />
  </section>
</template>

<script>
const footModule_1 = () => import('@/pages/modules/foot/footModule_1.vue');
// const footModule_2 = () => import('@/pages/modules/foot/footModule_2.vue');

export default {
  components: {
    footModule_1,
    // footModule_2,
  },
  // props: {
  //   themeConfig: {
  //     type: Object,
  //     required: true,
  //   },
  //   deviceType: {
  //     type: String,
  //     default: '',
  //   },
  //   host: {
  //     type: String,
  //     default: '',
  //   },
  // },
  computed: {
    footModuleComponent () {
      const moduleType = 1;
      return `footModule_${moduleType}`;
      // return `footModule_1`;
    },
    passThroughProps () {
      const { ...rest } = this.$props;
      return rest;
    },

  },
};
</script>